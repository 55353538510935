/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import '@fortawesome/fontawesome-free/js/all';
import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import '@rails/actiontext';
import axios from 'axios';
import 'bootstrap';
import Rails from 'rails-ujs';
import 'trix';
import Turbolinks from 'turbolinks';
import '../src/utils/initSelects';
import '../styles/application.scss';
import ReactOnRails from 'react-on-rails';

import App from '../components/App';
import CampaignAssessmentApp from '../components/CampaignAssessmentApp';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  App,
  CampaignAssessmentApp
});

require.context('../images', true);

Rails.start();
Turbolinks.start();

library.add(fas);

document.addEventListener('turbolinks:load', () => {
  const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  axios.defaults.headers.common['X-CSRF-Token'] = token;
  axios.defaults.headers.common.Accept = 'application/json';
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

  dom.i2svg();

  document.querySelectorAll('input[type="password"]').forEach((passwordInput) => {
    const passwordToggleButton = document.createElement('a');
    passwordToggleButton.classList = 'btn btn-outline-dark';

    const passwordHiddenIcon = document.createElement('i');
    passwordHiddenIcon.classList.add('fas', 'fa-eye');

    const passwordHiddenIconContainer = document.createElement('span');
    passwordHiddenIconContainer.appendChild(passwordHiddenIcon);
    passwordToggleButton.appendChild(passwordHiddenIconContainer);

    const passwordVisibleIcon = document.createElement('i');
    passwordVisibleIcon.classList.add('fas', 'fa-eye-slash');

    const passwordVisibleIconContainer = document.createElement('span');
    passwordVisibleIconContainer.appendChild(passwordVisibleIcon);
    passwordToggleButton.appendChild(passwordVisibleIconContainer);

    passwordInput.parentElement.appendChild(passwordToggleButton);

    let isPasswordVisible = false;
    passwordVisibleIconContainer.hidden = true;
    passwordToggleButton.addEventListener('click', (event) => {
      event.preventDefault();
      isPasswordVisible = !isPasswordVisible;
      passwordHiddenIconContainer.hidden = isPasswordVisible;
      passwordVisibleIconContainer.hidden = !isPasswordVisible;
      passwordInput.setAttribute('type', isPasswordVisible ? 'text' : 'password');
    });
  });
});
